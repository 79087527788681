import * as React from "react"
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"

import smartCityLogo from '../images/smart-city-logo.svg'
import msLogo from '../images/stadt-muenster-tiefbau-logo.svg'
import cfmLogo from '../images/codeformuenster-light.svg'
import msdLogo from '../images/muenster-school-design-logo.svg'
import swarcoLogo from '../images/swarco-logo.svg'
import wwuLogo from '../images/wwu-logo.svg'
import gobetaLogo from '../images/gobeta-logo.svg'
import zweitagLogo from '../images/zweitag-logo.svg'

// markup
const Leezenlow = () => {
  return (
    <Layout pageTitle="Leezenflow">
      <div className="container bg-dark text-light p-5">
        <div className="row">
          <div className="col">
            <div className="text-center">
              <h1 className="text-center mb-5">Leezenflow</h1>
              <StaticImage src="../images/leezenflow.jpg" />
            </div>

            <h2 className="mt-5 mb-3">Über das Projekt</h2>
            <p>
              Im Auftrag der Stadt Münster, <a href="https://smartcity.ms/" target="_blank" rel="noreferrer">Stabsstelle Smart City</a>, entwickelte bCyber die Hard- und Software für das Projekt <a href="https://smartcity.ms/leezenflow/" target="_blank" rel="noreferrer">Leezenflow</a>.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="mt-5 mb-3">Links</h2>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <a href="https://github.com/bCyberGmbH/leezenflow-doku" className="btn btn-primary btn-lg">Dokumentation</a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="mt-5 mb-3">Presse</h2>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col">
            <ul>
              <li>
                <a href="https://www.wn.de/muenster/hacken-fur-ein-besseres-munster-968354" target="_blank" rel="noreferrer">WN: Hacken für ein besseres Münster  (30.09.2019)</a>
              </li>
              <li>
                <a href="https://www.wn.de/muenster/auf-der-grunen-welle-uber-die-promenade-811820" target="_blank" rel="noreferrer">WN: Auf der grünen Welle über die Promenade (08.09.2020)</a>
              </li>
              <li>
                <a href="https://www.wn.de/muenster/munsterhack-ideen-fur-die-smarte-stadt-811612" target="_blank" rel="noreferrer">WN: „Münsterhack“ - Ideen für die smarte Stadt (23.09.2020)</a>
              </li>
              <li>
                <a href="https://www.muenster.de/pressemeldungen/web/frontend/index.php?show=1071689" target="_blank" rel="noreferrer">Stadt Münster: Leezenflow: Flüssiges Radfahren ohne Stopps (19.05.2021)</a>
              </li>
              <li>
                <a href="https://www.wn.de/muenster/grune-welle-assistent-fur-fahrradfahrer-wird-getestet-1034006" target="_blank" rel="noreferrer">WN: Grüne-Welle-Assistent für Fahrradfahrer wird getestet (19.05.2021)</a>
              </li>
              <li>
                <a href="https://www.staedtetag.de/files/dst/docs/Publikationen/Staedtetag-aktuell/2021/staedtetag-aktuell-5-2021.pdf#page=12" target="_blank" rel="noreferrer">Städtetag Aktuell Ausgabe 5|2021 (Juni 2021)</a>
              </li>
              <li>
                <a href="https://www.wn.de/muenster/halbzeit-beim-leezenflow-1040071" target="_blank" rel="noreferrer">WN: Halbzeit beim „Leezenflow“ (01.06.2021)</a>
              </li>
              <li>
                <a href="https://www1.wdr.de/nachrichten/westfalen-lippe/leezenflow-muenster-promenade-100.html" target="_blank" rel="noreferrer">WDR: Grüne Welle für Radfahrer auf Münsters Promenade (02.06.2021)</a>
              </li>
              <li>
                <a href="https://www.wn.de/muenster/munster-wird-durch-forderung-des-bundes-smart-city-1910928" target="_blank" rel="noreferrer">WN: Münster wird durch Förderung des Bundes „Smart City“ (16.07.2021)</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="mt-5 mb-3">Projektpartner</h2>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://smartcity.ms/" target="_blank" rel="noreferrer" className="partner-link">
            <img src={smartCityLogo} alt="Logo Smart City Münster" class="img-fluid" className="partner-logo-svg" />
              <p class="mt-2">
                Stabsstelle Smart City, Stadt Münster
              </p>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://www.stadt-muenster.de/tiefbauamt/startseite" target="_blank" rel="noreferrer" className="partner-link">
              <img src={msLogo} alt="Logo Tiefbauamt Stadt Münster" class="img-fluid" className="partner-logo-svg" />
              <p class="mt-2">
                Amt für Mobilität und Tiefbau, Stadt Münster
              </p>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://codeformuenster.org/" target="_blank" rel="noreferrer" className="partner-link">
              <img src={cfmLogo} alt="Logo Code for Münster" class="img-fluid" className="partner-logo-svg" />
              <p class="mt-2">
                Code for Münster
              </p>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://www.fh-muenster.de/msd/index.php" target="_blank" rel="noreferrer" className="partner-link">
              <img src={msdLogo} alt="Logo FH Münster, Münster School of Design" class="img-fluid" className="partner-logo-svg" />
              <p class="mt-2">
                FH Münster, Münster School of Design
              </p>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://www.swarco.com/de" target="_blank" rel="noreferrer" className="partner-link">
              <img src={swarcoLogo} alt="Logo Swarco" class="img-fluid" className="partner-logo-svg" />
              <p class="mt-2">
                Swarco
              </p>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://www.wiwi.uni-muenster.de/ivm/" target="_blank" rel="noreferrer" className="partner-link">
              <img src={wwuLogo} alt="Logo Universität Münster, Institut für Verkehrswissenschaft" class="img-fluid" className="partner-logo-svg" />
              <p class="mt-2">
                Universität Münster, Institut für Verkehrswissenschaft
              </p>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2 className="mt-5 mb-3">Unterstützer</h2>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://muensterhack.de/" target="_blank" rel="noreferrer" className="partner-link">
              <StaticImage src="../images/mshack-logo.jpg" className="partner-logo" height={250} width={250} transformOptions={{ fit: "inside" }} />
              <p class="mt-2">
                MÜNSTERHACK
              </p>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://gobeta.de/" target="_blank" rel="noreferrer" className="partner-link">
              <img src={gobetaLogo} alt="Logo gobeta" class="img-fluid" className="partner-logo-svg" />
              <p class="mt-2">
                GoBeta
              </p>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 text-center mt-5">
            <a href="https://www.zweitag.de/" target="_blank" rel="noreferrer" className="partner-link">
              <img src={zweitagLogo} alt="Logo zweitag" class="img-fluid" className="partner-logo-svg" />
              <p class="mt-2">
                Zweitag
              </p>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Leezenlow
